@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&family=Play:wght@700&display=swap");


@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;1,300&display=swap');

.password-toggle-icon {
  position: absolute;
  top:8px;
  right: 10px;
  z-index: 1000;
  cursor: pointer;
}

/* .input-text{
  @apply font-poppins text-black bg-transparent focus:outline-none border border-r-0 border-t-0 border-l-0 tracking-wide;
} */
:root {
  --body_background: white;
  --body_color:black;
  --link_color:navy;
}

[data-theme="dark"]{
  --body_background:#282c34;
  --body_color:white;
  --body_color:cyan;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body{
  height: 100vh;
}


body{
 font-family:  font-Montserrat, Arial, Helvetica, sans-serif;
 font-size: 16px;
 /* color: #fff; */
}

.main {
  display: flex;
  height: 100vh;
}

.sidebar{
width: 260px;
flex-shrink: 0;
background-color: rgba(22,22,22, 1);
height: 100%;
overflow: auto;
}


.container{
  flex-grow: 1;
  padding: 2em;
  /* background-image: radial-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, .8)); */
  /* , url('../public/banner.png'); */
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.title{
  font-size: 3em;
}
.info{
  font-size: 1.1em;
  letter-spacing: 1px;
  line-height: 1.5;
  margin: 1.5em;
  color: rgb(224, 224, 224);
}
.btn{
  margin: 0 auto;
  border: none;
  outline: none;
  padding: .75em 1em;
  font-size: 1em;
  letter-spacing: 1px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, .4);
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 3px;
  background-color: rgb(134, 49, 0);
  color: #fff;
}

.sidebar{
  width: 260px;
  flex-shrink: 0;
  background-color: rgba(22, 22, 22, 1);
  height: 100%;
  overflow: auto;
}


.sidebar-item{
  padding: .75em 1em;
  display: block;
  transition: background-color .15s;
  border-radius: 5px;
}
.sidebar-item:hover{
  background-color: rgba(255, 255, 255, .1);
}

.sidebar-title{
  display: flex;
  font-size: 1.2em;
  justify-content: space-between;
}
.sidebar-title span i{
  display: inline-block;
  width: 1.5em;
}
.sidebar-title .toggle-btn{
  cursor: pointer;
  transition: transform .3s;
}
.sidebar-item.open > .sidebar-title .toggle-btn{
  transform: rotate(180deg);
}
.sidebar-content{
  padding-top: .25em;
  height: 0;
  overflow: hidden;
}
.sidebar-item.open > .sidebar-content{
  height: auto;
}

.sidebar-item.plain{
  color: #fff;
  text-decoration: none;
}
.sidebar-item.plain:hover{
  text-decoration: underline;
}
.sidebar-item.plain i{
  display: inline-block;
  width: 1.7em;
}


/*  */
@layer base {
  button {
    @apply border border-black rounded-b-xl px-5 py-1
  }
  
  }

/* @layer components {
  body {
    @apply overflow-x-hidden font-Montserrat
  }
} */


/* Compiled dark classes from Tailwind */
.dark .dark\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgba(55, 65, 81);
}
.dark .dark\:bg-gray-50 {
  background-color: rgba(249, 250, 251);
}
.dark .dark\:bg-gray-100 {
  background-color: rgba(243, 244, 246);
}
.dark .dark\:bg-gray-600 {
  background-color: rgba(75, 85, 99);
}
.dark .dark\:bg-gray-700 {
  background-color: rgba(55, 65, 81);
}
.dark .dark\:bg-gray-800 {
  background-color: rgba(31, 41, 55);
}
.dark .dark\:bg-gray-900 {
  background-color: rgba(17, 24, 39);
}
.dark .dark\:bg-red-700 {
  background-color: rgba(185, 28, 28);
}
.dark .dark\:bg-green-700 {
  background-color: rgba(4, 120, 87);
}
.dark .dark\:hover\:bg-gray-200:hover {
  background-color: rgba(229, 231, 235);
}
.dark .dark\:hover\:bg-gray-600:hover {
  background-color: rgba(75, 85, 99);
}
.dark .dark\:hover\:bg-gray-700:hover {
  background-color: rgba(55, 65, 81);
}
.dark .dark\:hover\:bg-gray-900:hover {
  background-color: rgba(17, 24, 39);
}
.dark .dark\:border-gray-100 {
  border-color: rgba(243, 244, 246);
}
.dark .dark\:border-gray-400 {
  border-color: rgba(156, 163, 175);
}
.dark .dark\:border-gray-500 {
  border-color: rgba(107, 114, 128);
}
.dark .dark\:border-gray-600 {
  border-color: rgba(75, 85, 99);
}
.dark .dark\:border-gray-700 {
  border-color: rgba(55, 65, 81);
}
.dark .dark\:border-gray-900 {
  border-color: rgba(17, 24, 39);
}
.dark .dark\:hover\:border-gray-800:hover {
  border-color: rgba(31, 41, 55);
}
.dark .dark\:text-white {
  color: rgba(255, 255, 255);
}
.dark .dark\:text-gray-50 {
  color: rgba(249, 250, 251);
}
.dark .dark\:text-gray-100 {
  color: rgba(243, 244, 246);
}
.dark .dark\:text-gray-200 {
  color: rgba(229, 231, 235);
}
.dark .dark\:text-gray-400 {
  color: rgba(156, 163, 175);
}
.dark .dark\:text-gray-500 {
  color: rgba(107, 114, 128);
}
.dark .dark\:text-gray-700 {
  color: rgba(55, 65, 81);
}
.dark .dark\:text-gray-800 {
  color: rgba(31, 41, 55);
}
.dark .dark\:text-red-100 {
  color: rgba(254, 226, 226);
}
.dark .dark\:text-green-100 {
  color: rgba(209, 250, 229);
}
.dark .dark\:text-blue-400 {
  color: rgba(96, 165, 250);
}
.dark .group:hover .dark\:group-hover\:text-gray-500 {
  color: rgba(107, 114, 128);
}
.dark .group:focus .dark\:group-focus\:text-gray-700 {
  color: rgba(55, 65, 81);
}
.dark .dark\:hover\:text-gray-100:hover {
  color: rgba(243, 244, 246);
}
.dark .dark\:hover\:text-blue-500:hover {
  color: rgba(59, 130, 246);
}

/* Custom style */
.header-right {
    width: calc(100% - 3.5rem);
}
.sidebar:hover {
    width: 16rem;
}
@media only screen and (min-width: 768px) {
    .header-right {
        width: calc(100% - 16rem);
    }        
}
